export function detectSectionChange ({
    sectionsStaticRef, 
    currentSectionRef,
    sectionId
}){

    if(sectionId.toString() === currentSectionRef.current.toString()) return;

    currentSectionRef.current = sectionId;

    let currentSectionData = sectionsStaticRef.current[sectionId-1];

    const event = new CustomEvent('sectionChanged', {
        detail: {
            sectionId: sectionId,
            currentSectionData: currentSectionData
        }
    });
    
    window.dispatchEvent(event);




}