import { detectSectionChange } from "../detectSectionChange";
import { controlAudioPlay } from "../../controlAudioPlay";
export function handle_dialogue({
    isDialogueChanged,
    section_id,
    created_at,
    assistant_message,
    audio_data,
    accumulateAssistantAudio,
    handle_transcript_id,
    isFinished,
    sectionsStaticRef,
    currentSectionRef,
}){
    try {

        if (!window.AssemblySpokeAt) window.AssemblySpokeAt = 0;
        if (!window.VADStartLastSpokeAt) window.VADStartLastSpokeAt = 0;
        if (!window.VADStopLastSpokeAt) window.VADStopLastSpokeAt = 0;

        detectSectionChange({
          sectionsStaticRef,
          currentSectionRef,
          sectionId: section_id,
        })
        // console.log("window.AssemblySpokeAt",window.AssemblySpokeAt)
        if (
          (
            created_at > window.VADStartLastSpokeAt
            && created_at > window.VADStopLastSpokeAt
            && window.VADStopLastSpokeAt > window.VADStartLastSpokeAt
            && created_at> window.AssemblySpokeAt
          )
          // ||
          // (
          //   Date.now() - window.VADStopLastSpokeAt > 350
          //   && window.VADStopLastSpokeAt > window.VADStartLastSpokeAt
          // )
        ) {

          // if (
          //   !window.isAssistantSpeakingGap
          //   ||
          //   window?.exemptTranscriptIds?.includes(handle_transcript_id)
            
          // ) {
            let controlAudioPlayed = controlAudioPlay({
              isDialogueChanged,
              assistant_message,
              audio_data,
              accumulateAssistantAudio,
              handle_transcript_id
            });

            window.isAssistantSpeakingGap = true
            window.exemptTranscriptIds = [handle_transcript_id];
          // }

          if (isFinished) {
            let complete = new CustomEvent("isFinished", { detail: { message: "Interview is finished" } });
            window.dispatchEvent(complete);
          }

        } 
      } catch (err) {
        console.log(err);
      }
}