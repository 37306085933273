import { triggerVAD } from "./triggerVAD";
export function untriggerVAD ({
    isUserSpeaking,
    isSpeechOn, 
}){

    console.log(">>> VAD CLAIMS: STOPPED SPEAKING")


    window.VADStopLastSpokeAt = Date.now();
    window.isUserSpeaking = false;
    const stoppedSpeakingEvent = new CustomEvent('stoppedSpeakingEvent');
    isUserSpeaking.current = false;
    document.dispatchEvent(stoppedSpeakingEvent);
    window.dispatchEvent(stoppedSpeakingEvent);



    setTimeout (()=>{
        


            if((Date.now() - window.AssemblySpokeAt>1500&&Date.now()-window.VADStartLastSpokeAt>1500)){
         

                window.VADStartLastSpokeAt = Date.now()-1005;
                window.isAssistantSpeakingGap = false;


        
                window.streamResponseSocket.send(JSON.stringify({
                    message_type: "system",
                    system_message: "The user isn't saying anything more. If they have answered the question continue as normal If they havent finished or started the answer offer to repeat or clarify.",
                  }))

                window.streamResponseSocket.send(JSON.stringify({
                    transcript_boolean: true,
                    interview_id:window.interviewId,
                    created_at: Date.now(),
                    message_type: "transcript",
                  
                  }));

            }


        

    }, 1000)

}