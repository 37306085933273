export function detectInterviewOverChange ({
    currentSectionRef, 
    sectionsStaticRef,
}){

    if(currentSectionRef.current === sectionsStaticRef.current.length) {

        
        window.addEventListener("isFinished", (e) => {
            console.log("Interview Over")
            const finishedEventVerified = new CustomEvent("finishedEventVerified", { detail: { message: "finishedEventVerified" } });
            window.dispatchEvent(finishedEventVerified);
        })
        
    }


    
}