import React, { useState, Children } from 'react';


function MenuTitle({
    title
}) {

    return (
        <div className='menu-divider mt-1 mb-1'>
        </div>
    )
}

export default MenuTitle;