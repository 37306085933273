import { triggerVAD } from "./VAD/triggerVAD";
import { untriggerVAD } from "./VAD/untriggerVAD";

const isSpeechProbabilities = [];
let isUserSpeakingLocal = false;
const threshold = 0.7;
const numberOfThresholdProbabilities = 3;

export function processProbabilities ({
    notSpeech,
    isSpeech, 
    isUserSpeaking, 
    isSpeechOn
}){

    isSpeechProbabilities.push(isSpeech);

    if(isSpeechProbabilities.length > 10){
        isSpeechProbabilities.shift();
    }


    if(isSpeech > threshold && isSpeechProbabilities[isSpeechProbabilities.length - 2] < threshold){
        console.log(">>> Crossed the thresh")
        const VADCrossedThreshold = new CustomEvent("VADCrossedThreshold", { detail: { message: "VADCrossedThreshold" } });
        window.dispatchEvent(VADCrossedThreshold);
    }

    const lastThreeProbabilities = isSpeechProbabilities.slice(isSpeechProbabilities.length - numberOfThresholdProbabilities, isSpeechProbabilities.length);
    const isUserNowSpeaking = lastThreeProbabilities.every(probability => probability > threshold);

    if(isUserNowSpeaking !== isUserSpeakingLocal){
        isUserSpeakingLocal = isUserNowSpeaking;
    } else return;


    if(isUserSpeakingLocal){
        triggerVAD({
            isUserSpeaking, 
            isSpeechOn
        });
        
    } else {
        untriggerVAD({
            isUserSpeaking, 
            isSpeechOn
        });
    }



}