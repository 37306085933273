export function triggerVAD({
    isUserSpeaking, 
    isSpeechOn
}) {

    window.isAssistantSpeakingGap = false;
    window.isUserSpeaking = true;
    window.VADStartLastSpokeAt = Date.now();

    const startedSpeakingEvent = new CustomEvent('startedSpeakingEvent');
    document.dispatchEvent(startedSpeakingEvent);
    window.dispatchEvent(startedSpeakingEvent);

    isUserSpeaking.current = true;

    if (window.streamResponseSocket && window.streamResponseSocket.readyState === 1) {

        if (isSpeechOn.current) {
            setTimeout(() => {
                window.streamResponseSocket.send(JSON.stringify({
                    message_type: "VAD_event",
                    event: "started_speaking",
                    timestamp: Date.now()
                }))

            })
        } else {
            if (isUserSpeaking.current) {
                setTimeout(() => {
                    window.streamResponseSocket.send(JSON.stringify({
                        message_type: "VAD_event",
                        event: "started_speaking",
                        timestamp: Date.now()
                    }))

                }, 300)
            }
        }
    }

         if (window.userSpeakingDuration.isDurationStartable) {
                    window.userSpeakingDuration.isDurationStartable = false;
                    window.userSpeakingDuration.startTime = Date.now();
                }



}