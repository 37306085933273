import {
    doc,
    collection,
    setDoc,
    addDoc,
    getDoc,
    serverTimestamp,
} from "firebase/firestore";
import { db } from "./firebase";

export async function createNewUserDocument(user) {
    const userRef = doc(db, "users", user.uid);
    const userDocRef = doc(db, 'users', user.uid);

    // Check if a document for the user already exists
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.exists()) {

        setDoc(userRef, {
            userId: user.uid,
            email: user.email,
        });
    }

}

export async function addEmailToWaitlist(email) {
    const waitlistRef = doc(db, "waitlist", email);
    const waitlistDoc = await getDoc(waitlistRef);

    if (!waitlistDoc.exists()) {
        setDoc(waitlistRef, {
            email: email,
            timestamp: serverTimestamp(),
        });

        return true;

    } else {
        return false;
    }
}




export async function addReportIssueError(errorDescription, interviewId) {
    const errorRef = collection(db, "reported_errors");

    addDoc(errorRef, {
        error_description: errorDescription,
        interview_id: interviewId,
        timestamp: serverTimestamp(),
    });

    return;
}