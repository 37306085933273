import React from 'react';
import Header from '../../components/applicationHeader/Header';
import Sidebar from './components/Sidebar';

const Dashboard = () => {
    return (
        <div className='h-[100vh] w-[100vw] bg-[#f5f5f6]'>
            <Header />
            <div className='flex'>

                
        
            </div>

        </div>
    );
};

export default Dashboard;
