import { createContext, useContext, useEffect, useState } from 'react';
import {
    onAuthStateChanged,
    signOut as authSignOut,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    GoogleAuthProvider,
    signInWithPopup,
} from 'firebase/auth';
import { auth } from './firebase';
import { createNewUserDocument } from './firestore';


export default function useFirebaseAuth() {
    const [authUser, setAuthUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const clear = () => {
        setAuthUser(null);
        setIsLoading(false);
    };

    const authStateChanged = async (user) => {
        setIsLoading(true);
        if (!user) {
            clear();
            return;
        }
        setAuthUser({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
        });
        setIsLoading(false);
    };

    const signUpWithEmailAndPassword = async (email, password) => {
        try {
            await createUserWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
                const user = userCredential.user;

                createNewUserDocument(user);
            });
        } catch (error) {
            return mapAuthCodeToMessage(error.code);
        }
        return null;
    };

    const signInWithGoogle = async () => {
        try {
            const provider = new GoogleAuthProvider();
            await signInWithPopup(auth, provider).then(async (userCredential) => {
                const user = userCredential.user;

                createNewUserDocument(user);
            })
        } catch (error) {
            return mapAuthCodeToMessage(error.code);
        }
        return null;
    };

    const signOut = () => authSignOut(auth).then(clear);

    const signIn = async (email, password) => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            return mapAuthCodeToMessage(error.code);
        }
        return null;
    };


    const resetForgottenPassword = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email);
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                return null;
            }
            return mapAuthCodeToMessage(error.code);
        }
        return null;
    };


    // Listen for Firebase Auth state change
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, authStateChanged);
        return () => unsubscribe();
    }, []);

    return {
        authUser,
        isLoading,
        signOut,
        signIn,
        signUpWithEmailAndPassword,
        resetForgottenPassword,
        signInWithGoogle,
    };
}

const AuthUserContext = createContext({
    authUser: null,
    isLoading: true,
    signOut: async () => { }
});

export function AuthUserProvider({ children }) {
    const auth = useFirebaseAuth();
    return <AuthUserContext.Provider
        value={auth}
    >
        {children}
    </AuthUserContext.Provider>;
}

export const useAuth = () => useContext(AuthUserContext);

export function mapAuthCodeToMessage(code) {
    switch (code) {
        case 'auth/invalid-email':
            return 'Invalid email address format';
        case 'auth/missing-email':
            return 'Please enter an email address';
        case 'auth/user-not-found':
            return 'User with this email not found';
        case 'auth/wrong-password':
            return 'Incorrect password';
        case 'auth/email-already-in-use':
            return 'Email address already in use';
        case 'auth/weak-password':
            return 'Password should be at least 6 characters';
        default:
            return 'Something went wrong. Please contact support';
    }
}