import React, { useEffect, useRef } from 'react'
import { controlAudioPlay } from './controlAudioPlay';
import { initialiseDeepgramSocket, closeDeepgramSocket } from './initialiseDeepgramSocket';
import { detectSectionChange } from './functions/detectSectionChange';
import { detectInterviewOverChange } from './functions/detectEndInterview.js';
import { handle_dialogue } from './functions/Interview/handle_dialogue.js';

let previousSectionLength = null;

const useCreateWebSocket = ({
  active,
  interviewId,
  isMuted = { current: false }
}) => {


  const sectionsStaticRef = useRef([]);
  const currentSectionRef = useRef(0);


  window.isUseCreateSocketHookMounted = true;
  const accumulateAssistantAudio = useRef("");
  if (!interviewId) {
    interviewId = window.interviewId
  }

  useEffect(() => {
    if (active && !window.useCreateWebSocketMounted) {


      const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

      detectInterviewOverChange({
        currentSectionRef,
        sectionsStaticRef,
      });

      let streamResponseSocket;


      if (isLocalhost) {
        streamResponseSocket = new WebSocket("ws://localhost:8080");
      } else {
        streamResponseSocket = new WebSocket("wss://humanvue-audio-stream-ivw5t6wsua-ew.a.run.app");
      }



      streamResponseSocket.onopen = () => {
        const msg = JSON.stringify({
          message_type: 'interview_id',
          interview_id: window.interviewIdSpec || interviewId,
        });
        streamResponseSocket.send(msg);
      };

      streamResponseSocket.onmessage = async (event) => {
        const eventData = JSON.parse(event.data);
        console.log("eventData", eventData);



        const { audio_data, handle_transcript_id, data, assistant_message, section_length, sections_static, created_at, section_id, message_type, message, deepgram_keywords, redirect, pre_code, isFinished } = eventData;

        switch (message_type) {

          case "onconnection":
            initialiseDeepgramSocket({
              deepgram_keywords,
              isMuted
            });
            sectionsStaticRef.current = sections_static;

            break;

          case "complete":

            let complete = new CustomEvent("complete", { detail: { message: "complete" } });
            window.dispatchEvent(complete);

            break;

          case "affirmation":
            let affirmation = new CustomEvent("affirmation", { detail: { message: "affirmation" } });
            window.dispatchEvent(affirmation);


          case "midthought":
            let midthought = new CustomEvent("midthought", { detail: { message: "midthought" } });
            window.dispatchEvent(midthought);
            break;

          case "set_ide_pre_code":
            let set_ide_pre_code = new CustomEvent("set_ide_pre_code", { detail: { code: pre_code } });
            window.dispatchEvent(set_ide_pre_code);

          case "set_ide_swe_data":

            let set_ide_swe_data = new CustomEvent("set_ide_swe_data", { detail: { data } });
            window.dispatchEvent(set_ide_swe_data);

          case "dialogue":

            let isDialogueChanged = section_length !== previousSectionLength;
            previousSectionLength = section_length;

            // insert dialogue
            handle_dialogue({
              isDialogueChanged,
              section_id,
              created_at,
              assistant_message,
              audio_data,
              accumulateAssistantAudio,
              handle_transcript_id,
              isFinished,
              sectionsStaticRef,
              currentSectionRef,
          })
            break;

          case "chat":

            let chatEvent = new CustomEvent("incomingMessage", {
              detail: {
                messageType: message.type,
                messageContent: message.content,
                sectionId: message.section_id
              }
            });

            window.dispatchEvent(chatEvent);


            break;

          case "interview_saved":
            break;

          default:
            break;
        }
      };


      streamResponseSocket.onerror = error => console.log("WebSocket error:", error);
      streamResponseSocket.onclose = () => console.log("WebSocket connection is closed.");
      console.log("connected window to streamResponseSocket")
      window.streamResponseSocket = streamResponseSocket;
    }
  }, [active, interviewId])


};


export default useCreateWebSocket;