const audioContext = new AudioContext()
let isPlaying = false;
let audioBufferQueue = [];


export const handlePlaySound = async ({ base64string, assistantMessage, handle_transcript_id }) => {
    const arrayBuffer = base64ToArrayBuffer(base64string);
    const audioBuffer = await loadAudioBuffer(new Blob([arrayBuffer]), audioContext);
    audioBufferQueue.push({ audioBuffer, assistantMessage, handle_transcript_id });
    playNextBuffer();
    return audioBuffer;
};

export const playNextBuffer = () => {

    if (audioBufferQueue.length > 0 && !isPlaying) {

        isPlaying = true;
        const nextBuffer = audioBufferQueue.shift();
        const { audioBuffer, assistantMessage, handle_transcript_id } = nextBuffer;
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        const gainNode = audioContext.createGain();
        gainNode.gain.value = 1; // set gain value to reduce volume
        source.connect(gainNode);
        gainNode.connect(audioContext.destination);
        

        window.addEventListener("VADCrossedThreshold", ()=>{
            source.playbackRate.value = 0; 
            setTimeout(()=>{
                source.playbackRate.value = 1; 
            
            }, 250)

            
        })
        

        

        const triggerSpeechEvent = new CustomEvent('triggerSpeechEvent', { detail: { duration: source.buffer.duration } });

        window.dispatchEvent(triggerSpeechEvent);

        source.onended = () => {
            isPlaying = false;
            playNextBuffer();
            source.disconnect();
        };
        source.start();

        

        document.addEventListener('startedSpeakingEvent', () => {

            audioBufferQueue = [];
            source.stop();
            source.disconnect();
            isPlaying = false;
            window.exemptTranscriptIds = [];
        });

        const audioDuration = audioBuffer.duration;
        const setAssistantTextEvent = new CustomEvent('setAssistantTextEvent', {
            detail: {
                assistantMessage,
                audioDuration
            }

        });
        window.dispatchEvent(setAssistantTextEvent);
    }
    
};

export const base64ToArrayBuffer = (base64String) => {
    const binaryString = atob(base64String);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

export const loadAudioBuffer = async (blob, audioContext) => {
    const arrayBuffer = await blob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    return audioBuffer;
}