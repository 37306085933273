import React, { useEffect, useState, useRef } from 'react';
import { processProbabilities } from './functions/processProbabilities';
import { triggerVAD } from './functions/VAD/triggerVAD';
import { untriggerVAD } from './functions/VAD/untriggerVAD';

function VADWidget() {

    const [socketOpen, setSocketOpen] = useState(false);
    const [socket, setSocket] = useState(null);
    window.userSpeakingDuration = { durationStartTime: 0, isDurationStartable: true };
    const isSpeechOn = useRef(false);
    const isUserSpeaking = useRef(false);

    useEffect(() => {

        window.addEventListener("triggerSpeechEvent", (event) => {

            isSpeechOn.current = true;

            setTimeout(() => {
                isSpeechOn.current = false;

            }, 300)


        })
    }, [])


    useEffect(() => {
        console.log("initialising VAD socket")
        const socket = new WebSocket('wss://vad-node-st-ivw5t6wsua-ew.a.run.app');

        socket.onopen = () => {
            console.log("Connected to VAD Socket")
            setSocketOpen(true);
        };

        socket.onmessage = (message) => {
            const data = JSON.parse(message.data);

            if (data.event === 'probabilities') {

                const probabilitiesEvent = new CustomEvent('probabilitiesEvent', { detail: data });
                const {notSpeech, isSpeech} = data.data;
                window.dispatchEvent(probabilitiesEvent);

                processProbabilities({
                    notSpeech, 
                    isSpeech, 
                    isUserSpeaking, 
                    isSpeechOn
                });




            }
        };

        socket.onerror = (error) => {
            console.log('WebSocket error: ', error);
        };

        socket.onclose = () => {
            setSocketOpen(false);
            console.log('WebSocket connection closed.');
        };

        setSocket(socket);

        return () => {
            socket.close();
            setSocketOpen(false);
        };
    }, []);

    useEffect(() => {

        if (socketOpen) { // Only run if socket is open and recording has started
            const audioContext = new (window.AudioContext || window.webkitAudioContext)({
                sampleRate: 16000,
                // noiseSuppression: true,
            });

            console.log("after audio context")
            navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false,

            })
                .then(stream => {



                    const microphone = audioContext.createMediaStreamSource(stream);
                    const processor = audioContext.createScriptProcessor(1024, 1, 1);
                    microphone.connect(processor);
                    processor.connect(audioContext.destination);



                    processor.onaudioprocess = (event) => {



                        var input = event.inputBuffer.getChannelData(0) || new Float32Array(this.bufferSize);
                        var int16 = new Int16Array(input.length);
                        for (var i = 0; i < input.length; i++) {
                            int16[i] = 32767 * Math.min(1, input[i]);
                        }
                        if (socket.readyState === WebSocket.OPEN) socket.send(int16.buffer);

                    };

                })
                .catch(error => console.log('Error: ', error));
        }
    }, [socketOpen]); // Add recordingStarted as a dependency

    return (
        <div className='h-0 overflow-hidden absolute'>
            <h2>VAD Status:</h2>


        </div>
    );
}

export default VADWidget;