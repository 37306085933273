import { initializeApp } from "firebase/app";
import { getFunctions } from 'firebase/functions';
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCYbR9t78xzKW5O7TleqHDSlkvdC7JbN3k",
  authDomain: "canopyai.xyz",
  databaseURL: "https://humanview-d6bc8-default-rtdb.firebaseio.com",
  projectId: "humanview-d6bc8",
  storageBucket: "humanview-d6bc8.appspot.com",
  messagingSenderId: "656601343549",
  appId: "1:656601343549:web:57712a90bb8744bb7aec54",
  measurementId: "G-BYBNK7PB2W"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, "us-central1");
export const analytics = getAnalytics(app);