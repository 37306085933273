import { handlePlaySound } from './audioUtils.js';
let interviewerTextResponse = ""

export function controlAudioPlay({
  isDialogueChanged,
  assistant_message,
  audio_data,
  accumulateAssistantAudio,
  handle_transcript_id
}) {


  if (isDialogueChanged) {
    interviewerTextResponse = assistant_message + " ";
  }

  else {

    interviewerTextResponse += (assistant_message + " ");
    accumulateAssistantAudio += (assistant_message + " ");

  }

  window.streamResponseSocket.send(
    JSON.stringify({
      message_type: "clear",
      assistant_message: assistant_message,
      type: "assistant",
    })
  )

  const audioBuffer = handlePlaySound({
    base64string:audio_data, 
    assistantMessage:assistant_message, 
    handle_transcript_id
  });

  let setSubtitleEvent = new CustomEvent('setSubtitleEvent', {

    detail: interviewerTextResponse

  });

  document.dispatchEvent(setSubtitleEvent);








}
