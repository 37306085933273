let socket = null;
let isMounted = false;

export const initialiseDeepgramSocket = async ({
  deepgram_keywords,
  isMuted = { current: false }
}) => {
  if (isMounted) return;
  isMounted = true;


  const interviewId = window.interviewId
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
  const mediaRecorder = new MediaRecorder(stream)

  let base_deepgreen_url = "wss://api.deepgram.com/v1/listen?model=nova-2-ea&keywords=Canopy&interim_results=true"


  if (deepgram_keywords && deepgram_keywords.length > 0) {
    deepgram_keywords.forEach((keyword) => {

      base_deepgreen_url += `&keywords=${keyword}:2`
    })
  }
  

  socket = new WebSocket(base_deepgreen_url, ['token', 'e22de31cc58a95073564533b6245cde157249e0d'])

  socket.onopen = () => {


    mediaRecorder.addEventListener('dataavailable', event => {


      if (socket && event.data.size > 0 && socket.readyState == 1) {
        socket.send(event.data)
      }
    })
    mediaRecorder.start(250)



  }

  socket.onmessage = (message) => {

    const received = JSON.parse(message.data)

    if (!received.channel) return;
    const is_final = received.is_final
    const transcript = received.channel.alternatives[0].transcript

    if(!is_final){

      return
    }  else {
    }



    

    if (transcript) {

      const transcriptEvent = new CustomEvent('transcriptDeepgramEvent', {
        detail: {
          transcript
        }
      })

      window.dispatchEvent(transcriptEvent)

      if (window.streamResponseSocket && !isMuted.current) {

        window.streamResponseSocket.send(JSON.stringify({
          message_type: "clear",
          user_message: transcript,
          type: "user",
        }))

        window.AssemblySpokeAt = Date.now() - 1;

        const getResponse = async ({ interview_id, transcript_content }) => {
          if(transcript_content.trim() ==="hi") return

          if(window.isAssistantSpeaking) return
          if(Date.now() - window.lastStoppedSpeakingTime < 500) return

          window.streamResponseSocket.send(JSON.stringify({
            transcript_boolean: true,
            interview_id,
            created_at: Date.now(),
            message_type: "transcript",
            transcript_data: {
              role: 'user',
              transcript_content
            }
          }));
        };




        if (!window.VADStartLastSpokeAt) window.VADStartLastSpokeAt = Date.now() - 2
        if (!window.VADStopLastSpokeAt) window.VADStopLastSpokeAt = Date.now();


        if (window.VADStopLastSpokeAt > window.VADStartLastSpokeAt) {
          getResponse({ interview_id: interviewId, transcript_content: transcript })
        } else {
          console.log("-- recontinued speaking, canceling request --")
        }

      }
    }

  }

  socket.onclose = () => {
    console.log({ event: 'onclose' })

    initialiseDeepgramSocket({ deepgram_keywords, isMuted })

  }

  socket.onerror = (error) => {
    console.log({ event: 'onerror', error })
  }


};


export const closeDeepgramSocket = () => {
  socket.close()
  socket = null
}